import { useLocation } from "@reach/router";
import differenceInDays from "date-fns/differenceInDays";
import { navigate } from "gatsby";
import merge from "lodash/merge";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Constants } from "../../../@types/Constants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import useMobileDetect from "../../../hooks/useMobileDetect";
import { useRoomDetail } from "../../../hooks/useRoomDetail";
import {
  setCheckout,
  setPropertyPagePath,
} from "../../../redux/slices/Checkout/checkout";
import { hotelsSelector } from "../../../redux/slices/Hotel/hotel";
import { crmProfileSelector } from "../../../redux/slices/Member/member";
import {
  selectHotelRates,
  unlockPricing,
} from "../../../redux/slices/Rate/rate";
import {
  searchSelector,
  setcheckoutState,
} from "../../../redux/slices/Search/search";
import { RootState } from "../../../redux/store";
import { sortRooms } from "../../../services/helpers";
import { fetchRoomsAndRatesASE } from "../../../services/rates";
import {
  checkUserRedemptionEligiblity,
  getMemberPoints,
} from "../../../services/redemption";
import {
  addPropertyRoomsGTMDataLayer,
  addToCartGTMDataLayer,
  fireProductDetailEvent,
} from "../../../utils/datalayers";
import { convertArrayToObject } from "../../../utils/helpers";
import { Storage } from "../../../utils/storage";
import NotAvailableMessage from "../NotAvailableMessage/NotAvailableMessage";
import { LoadingSpinner } from "./HelperComponents/HotelRoomComponents";
import useFetchRates from "./HelperComponents/HotelRoomsHooks/useFetchRates";
import useFilterRooms from "./HelperComponents/HotelRoomsHooks/useFilterRooms";
import useLoadRoomDetails from "./HelperComponents/HotelRoomsHooks/useLoadRoomDetails";

import { IHotelRoomsProps, IRoomRates } from "./HotelRoomsProps";
import RoomFilters from "./HelperComponents/RoomFilters/RoomFilters";
import FilteredRooms from "./HelperComponents/FilteredRooms";

const HotelRooms: React.FC<IHotelRoomsProps> = (props) => {
  const checkout = useAppSelector((state: RootState) => state.checkout);
  const location = useLocation();
  const search = useAppSelector(searchSelector);
  const crmProfile = useAppSelector(crmProfileSelector);
  const isMobileOnly = useMobileDetect();
  const rates = useAppSelector(selectHotelRates([props.hotel.crs_code]));
  const [rooms, setRooms] = useState<IRoomRates[] | null>(null);
  const [roomRates, setRoomRates] = useState<IRoomRates[] | null>(null);
  const [roomsLoaded, setRoomsLoaded] = useState(false);
  const [loadingRates, setLoadingRates] = useState(false);
  const [updatingRates, setUpdatingRates] = useState(false);
  const [pricingInitialized, setPricingInitialized] = useState(false);
  const [loadingRoomDetail, , allRooms] = useRoomDetail({
    hotelCode: props.hotel.crs_code,
    crsName: props.hotel.crs_name,
  });
  const [roomsToDisplay, setRoomsToDisplay] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortOrderValue, setSortOrderValue] = useState("Lowest Price");
  const [filteredRooms, setFilteredRooms] = useState<any | null>(null);
  const [filteredRoomsLoaded, setFilteredRoomsLoaded] = useState(false);
  const [filterRoomType, setFilterRoomType] = useState("");
  const [accessibleFilter, setAccessibleFilter] = useState(false);
  const [filterOccupancy, _setFilterOccupancy] = useState("");
  const dispatch = useAppDispatch();
  const [showOfferMessage, setShowOfferMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [roomRatesParsed, setRoomRatesParsed] = useState(false);
  const [totalVisibleRoom, setTotalVisibleRoom] = useState(2);
  const [showFilterTabs, setShowFilterTabs] = useState(false);
  const [showRoomTypeFilter, setShowRoomTypeFilter] = useState(false);
  const [isPromoValid, setIsPromoValid] = useState(false);
  const [isGroupCodeValid, setIsGroupCodeValid] = useState(false);
  const hotels = useAppSelector(hotelsSelector);
  const hrDiscountUnlockedInStore = useAppSelector(
    (state) => state.show_special_pricing
  );
  const hrDiscountUnlockedInStorage = Storage.GetLocalStorageValue(
    "HR-discount-unlocked"
  );
  const isLoggedIn = useAppSelector((state) => state.member.isLoggedIn);
  const [fireEvent, setFireEvent] = useState(false);
  const redemptionItem = rates[props.hotel.crs_code as string]?.redemptionItem;

  const onRoomsLoad = useCallback((offerAvailable, rate, discountType) => {
    if (!offerAvailable) {
      const errorMsg = (
        <>
          Your {discountType} <strong>{rate}</strong> is unavailable for the
          selected dates. We are showing lowest available rates.
        </>
      );

      setShowOfferMessage(true);
      setMessage(errorMsg);
    }
    const element = document.getElementById("offer-message");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  const getHotelSrchPostionForDetail = useCallback(
    (hotels) => {
      const hotelList = Object.values(hotels);
      hotelList.sort((a, b) => a?.index - b?.index);
      const index = hotelList.findIndex(
        (hotel) => hotel.name === props.hotel.name
      );
      return index === -1 ? 1 : index + 1;
    },
    [props.hotel.name]
  );

  const getProductForDL = useCallback(() => {
    let selectedRoom = null;
    let price = null;
    let bestRateCode = null;
    let baseRate = null;
    let isThisHotelPromoValid = null;
    let isPromoValid = null;
    const crs = props.hotel.crs_code;
    if (crs && rates[crs as string]?.Rooms) {
      const parsedRooms = fetchRoomsAndRatesASE(
        rates[crs as string].Rooms,
        search.discount,
        search.promotionCode,
        search.groupCode,
        search.checkin,
        search.checkout,
        redemptionItem
      );

      if (parsedRooms !== null) {
        selectedRoom = parsedRooms[0];
        price = parsedRooms[0] ? parsedRooms[0].FromRate : null;
        bestRateCode = parsedRooms[0] ? parsedRooms[0].FromRateCode : null;
        baseRate = parsedRooms[0] ? parsedRooms[0].BaseRate : null;
        if (search.promotionCode || search.groupCode) {
          isThisHotelPromoValid = parsedRooms[0]
            ? parsedRooms[0].FromRatePromotional
            : null;
          if (!isPromoValid) {
            isPromoValid = isThisHotelPromoValid;
          }
        }
      }
    }

    const showUnlockButton =
      selectedRoom?.FromRateType === "member" && !isLoggedIn;
    const discountUnlocked =
      hrDiscountUnlockedInStore || hrDiscountUnlockedInStorage;
    const priceLocked = showUnlockButton && !discountUnlocked;
    const hotelBestPrice = price !== null ? parseFloat(price) : null;
    const hotelPriceForDL = priceLocked
      ? selectedRoom
        ? selectedRoom.BaseRate
        : null
      : hotelBestPrice;
    const hotelRateForDL = priceLocked
      ? selectedRoom
        ? selectedRoom.BaseRateCode
        : null
      : bestRateCode;
    return {
      name: props.hotel.name,
      hotelId: props.hotel.crs_code,
      list: search.searchString,
      category: hotelRateForDL,
      brand: props.hotel.relationships.brand_id.name,
      price: hotelPriceForDL,
      position: getHotelSrchPostionForDetail(hotels),
    };
  }, [
    props.hotel,
    rates,
    search,
    isLoggedIn,
    hrDiscountUnlockedInStore,
    hrDiscountUnlockedInStorage,
    redemptionItem,
    getHotelSrchPostionForDetail,
  ]);

  useEffect(() => {
    if (fireEvent) {
      const resultProductForDetail = getProductForDL();
      fireProductDetailEvent(resultProductForDetail, search.searchString);
    }
  }, [fireEvent]);

  useFetchRates(
    props,
    search,
    dispatch,
    setFireEvent,
    setLoadingRates,
    setRoomsLoaded,
    setUpdatingRates,
    setMessage,
    setIsPromoValid,
    setShowOfferMessage,
    setFilteredRoomsLoaded,
    setFilteredRooms,
    setRoomsToDisplay,
    setPricingInitialized,
    pricingInitialized,
    isLoggedIn
  );

  useLoadRoomDetails(
    pricingInitialized,
    rates,
    props,
    setRooms,
    setRoomsLoaded
  );

  useFilterRooms(
    roomsLoaded,
    loadingRates,
    roomRatesParsed,
    allRooms,
    roomRates,
    setFilteredRooms,
    setFilteredRoomsLoaded,
    accessibleFilter,
    setAccessibleFilter,
    filterRoomType,
    filterOccupancy,
    sortOrderValue,
    sortOrder,
    roomsToDisplay,
    search,
    checkout,
    props,
    addPropertyRoomsGTMDataLayer
  );

  useEffect(() => {
    if (!loadingRates && roomsLoaded) {
      const roomRatesFetched = fetchRoomsAndRatesASE(
        rooms,
        search.discount,
        search.promotionCode,
        search.groupCode,
        search.checkin,
        search.checkout,
        redemptionItem
      );
      const discountInSearch = search.groupCode || search.promotionCode;
      if (discountInSearch) {
        setSortOrder("asc");
        const offerAvailable =
          roomRatesFetched &&
          roomRatesFetched[0] &&
          roomRatesFetched[0].FromRatePromotional;
        const discountType = search.promotionCode ? "Promo code" : "Group code";
        setIsPromoValid(offerAvailable);
        offerAvailable ? setSortOrderValue("Promotional Price") : null;

        if (search.groupCode) {
          setIsGroupCodeValid(offerAvailable);
        }
        onRoomsLoad &&
          onRoomsLoad(offerAvailable, discountInSearch, discountType);
      } else {
        setSortOrderValue("Lowest Price");
      }
      setRoomRatesParsed(true);
      setRoomRates(roomRatesFetched);
    }
  }, [rooms, roomsLoaded, loadingRates, onRoomsLoad]);

  useEffect(() => {
    if (!loadingRates && roomsLoaded && roomRatesParsed && allRooms) {
      if (roomRates) {
        const ratesObject = convertArrayToObject(roomRates, "code");
        Object.keys(ratesObject).forEach((key) => {
          if (allRooms && allRooms[key]) {
            const updatedRoom = {
              ...ratesObject[key],
              RoomType: ratesObject[key].amenities.includes("Suite")
                ? "Suites"
                : "Rooms",
              galleryImages: allRooms[key].imageUrls.length
                ? allRooms[key].imageUrls.map((image) => ({
                    url: image,
                    alt: image,
                  }))
                : [],
            };

            ratesObject[key] = updatedRoom;
          }
        });
        merge(ratesObject, allRooms);
        let showTab = false;
        let allRoomTypes =
          ratesObject &&
          Object.keys(ratesObject).map((k) => ratesObject[k].RoomType);
        allRoomTypes = allRoomTypes.flat();
        if (
          allRoomTypes.includes(Constants.ROOM_AMINITY_ID) &&
          allRoomTypes.includes(Constants.SUITE_AMINITY_ID)
        ) {
          showTab = true;
        }
        setShowFilterTabs(true);
        setShowRoomTypeFilter(showTab);
        const mergedRoomsArray = Object.values(ratesObject);
        const sortedRooms = sortRooms(mergedRoomsArray);
        setRoomsToDisplay(sortedRooms);
      } else {
        const sortedRooms = sortRooms(Object.values(allRooms));
        setRoomsToDisplay(sortedRooms);
        setShowFilterTabs(false);
      }
    }
  }, [roomRates, allRooms, loadingRates, roomRatesParsed]);

  useEffect(() => {
    dispatch(
      setCheckout({ ...checkout, Step: "select_room", isResubmitted: false })
    );
    dispatch(setPropertyPagePath(location.pathname));

    return () => {
      setShowOfferMessage(false);
    };
  }, [location.pathname]);

  const handleTogleMobileFilter = useCallback(() => {
    setOpenFilter((prev) => !prev);
  }, []);

  const handleSortChange = useCallback((sortOrder: string) => {
    setSortOrder(sortOrder);
  }, []);

  const handleSelectRoom = async (event: React.MouseEvent<HTMLElement>) => {
    const dataset = (event.target as HTMLElement).dataset;
    const roomCode = dataset.room!;
    const isUnlockButton = dataset.isunlockbutton === "true";
    let NonMemberRates = true;
    const room = roomRates?.find((roomRate) => roomRate.RoomCode === roomCode);
    if (room?.Rates?.length === 1 && room?.Rates[0]?.rateType === "member") {
      NonMemberRates = false;
      dispatch(unlockPricing(true));
    }
    const rateCode =
      isUnlockButton && NonMemberRates ? room.BaseRateCode : room.FromRateCode;
    const rate = room?.Rates.find(
      (rateObject) => rateObject.rateCode === rateCode
    );
    const checkoutRooms = search.rooms.map((searchRoom, index) => ({
      ...searchRoom,
      room: index === 0 ? room : null,
      rate: index === 0 ? rate : null,
    }));
    const roomRenderedPrice = dataset?.roomprice;
    addToCartGTMDataLayer(
      props.hotel,
      room,
      rate,
      roomRenderedPrice,
      search.checkin,
      search.checkout,
      search.rooms?.length
    );
    const redemptionRate = rates[props.hotel.crs_code as string];
    dispatch(
      setcheckoutState({
        rooms: checkoutRooms,
        hotel: props.hotel,
        redemptionItem: redemptionRate?.redemptionItem || "",
        hotelLocation: redemptionRate?.hotelLocation || "",
        Brand: redemptionRate?.Brand || "",
      })
    );
    navigate("/checkout", {
      state: { rooms: checkoutRooms, hotel: props.hotel },
      replace: false,
    });
  };
  const getPoints = async (memberId: string) => {
    try {
      const response = await getMemberPoints(memberId);
      const numOfNights = differenceInDays(
        new Date(search.checkout),
        new Date(search.checkin)
      );
      const pointsRequiredPerDay = parseInt(redemptionItem?.currencyRequired);
      const isEligible = checkUserRedemptionEligiblity(
        parseInt(response?.memberPointsData?.available),
        pointsRequiredPerDay,
        numOfNights
      );
      dispatch((dispatch, getState) => {
        const currentCheckout = getState().checkout; // Get the latest state from Redux
        const newCheckout = {
          ...currentCheckout,
          isEligible,
          requiredPoints: pointsRequiredPerDay * numOfNights,
        };
        dispatch(setCheckout(newCheckout));
      });
    } catch (error) {
      // Handle error appropriately (e.g., logging or displaying an error message)
    }
  };

  useEffect(() => {
    // Trigger the getPoints function when the member is logged in and the memberId changes
    const memberId = crmProfile?.memberId;
    if (isLoggedIn && memberId) {
      getPoints(memberId);
    }
  }, [isLoggedIn, crmProfile]);

  return (
    <Container>
      {isMobileOnly && <hr style={{ marginTop: "20px" }} />}
      {loadingRates || loadingRoomDetail || !roomsLoaded || !roomRatesParsed ? (
        <LoadingSpinner />
      ) : (
        <>
          {showFilterTabs && (
            <RoomFilters
              openFilter={openFilter}
              background={props.background}
              showRoomTypeFilter={showRoomTypeFilter}
              sortOrder={sortOrder}
              handleSortChange={handleSortChange}
              setSortOrderValue={setSortOrderValue}
              sortOrderValue={sortOrderValue}
              accessibleFilter={accessibleFilter}
              setAccessibleFilter={setAccessibleFilter}
              filterRoomType={filterRoomType}
              setFilterRoomType={setFilterRoomType}
              handleTogleMobileFilter={handleTogleMobileFilter}
              isPromoValid={isPromoValid}
              isMobileOnly={isMobileOnly}
            />
          )}
          {filteredRooms && filteredRooms.length > 0 ? (
            <FilteredRooms
              filteredRooms={filteredRooms}
              isMobileOnly={isMobileOnly}
              totalVisibleRoom={totalVisibleRoom}
              handleSelectRoom={handleSelectRoom}
              setTotalVisibleRoom={setTotalVisibleRoom}
              updatingRates={updatingRates}
              isGroupCodeValid={isGroupCodeValid}
              hotel={props.hotel}
              hotelInfoUrgBgColor={props.hotelInfoUrgBgColor}
              hotelInfoUrgFontColor={props.hotelInfoUrgFontColor}
              redemptionItem={redemptionItem}
              showOfferMessage={showOfferMessage}
              message={message}
              setShowOfferMessage={setShowOfferMessage}
              search={search}
            />
          ) : (
            filteredRoomsLoaded && (
              <Row className={`${!showFilterTabs ? "mt-3" : ""}`}>
                <Col>
                  {filteredRooms && filteredRooms.length === 0 ? (
                    <NotAvailableMessage />
                  ) : null}
                </Col>
              </Row>
            )
          )}
        </>
      )}
    </Container>
  );
};

export default HotelRooms;
